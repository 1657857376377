import React from 'react'
import SEO from '../seo'

import TagsGrid from './tagsGrid'

const Tags = ({ tag, location, lang }) => {
  const { id, name, seoDescription, seoKeywords, seoTitle } = tag
  const pageSeoTitle = seoTitle && seoTitle !== '' ? seoTitle : name
  const pageSeoDescription =
    seoDescription && seoDescription !== '' ? seoDescription : name
  const pageSeoKeywords =
    seoKeywords && seoKeywords.length > 0 ? seoKeywords : [name]

  return (
    <React.Fragment>
      <h1 className="customH1">{name}</h1>
      <SEO
        title={pageSeoTitle}
        description={pageSeoDescription}
        meta={[
          {
            property: `keywords`,
            content: pageSeoKeywords
          },
          {
            property: `og:title`,
            content: pageSeoTitle
          },
          {
            property: `og:description`,
            content: pageSeoDescription
          },
          {
            property: `og:url`,
            content: location.href
          }
        ]}
        lang="ar"
        canonicalUrl={location.href}
      />
      <TagsGrid tagId={id} tagName={name} language={lang} />
    </React.Fragment>
  )
}

export default Tags
