import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Tags from '../components/tags'

import { addGoogleAnalyticsPageData } from '../utils/googleAnalyticsEvents'

const TagsTemplate = ({ pageContext, location }) => {
  const tag = pageContext.data
  const lang = 'ar'

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <Layout location={location} lang={lang}>
      <Tags tag={tag} lang={lang} location={location} />
    </Layout>
  )
}

export default TagsTemplate
