import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Grid from '../grid/grid'
import { getSize } from '../grid/helper'
import useViewportSize from '../../utils/useViewportSize'
import { GridLoader, GridError } from '../grid/grid.styled'

const TagsGrid = ({
  tagId,
  fetchTagById,
  resetTagById,
  tagData,
  language,
  tagName
}) => {
  const { width } = useViewportSize()

  // reset tag data when ID changes
  useEffect(() => {
    if (tagId) {
      const updatedGridSize = getSize(width)
      resetTagById()
      fetchTagData({ page: 0, gridSize: updatedGridSize })
    }
  }, [tagId])

  const fetchTagData = ({ page = tagData.page, gridSize }) => {
    fetchTagById(page, tagId, tagName, language, gridSize)
  }

  if (tagData.isError) {
    return (
      <GridLoader>
        <GridError>لايوجد معروضات</GridError>
      </GridLoader>
    )
  }

  return (
    <Grid
      mode="tag"
      language={language}
      gridData={tagData}
      key={tagId}
      fetchTagData={fetchTagData}
      resetTagById={resetTagById}
    />
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTagById: (page, tagId, tagName, language, gridSize, gridLength) => {
      const payload = {
        lang: language,
        id: tagId,
        page,
        tagName,
        gridSize,
        gridLength
      }
      dispatch({ type: 'FETCH_TAG_BY_ID', payload })
    },
    resetTagById: () => {
      dispatch({ type: 'TAG_BY_ID_RESET' })
    }
  }
}

const mapStateToProps = ({ tagById }) => {
  return {
    tagData: tagById
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsGrid)
